import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { createProject, getProjects, getProjectsTemplates } from '../../../../redux/actions/projects.actions';
import file from '../../../../assets/images/blank-project.svg';
import TemplateCard from '../../list/template-card';
import LimitationModal from '../limitation-modal';
import { getUser } from '../../../../redux/actions/user.actions';

function CreateProjectCarousel({ updateProjectCreating }) {
  const [selectedTemplateId, selectTemplateId] = useState(undefined);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitProject = (templateId) => {
    updateProjectCreating(true);
    const data = { template_id: templateId };
    dispatch(createProject(data)).then((response) => {
      dispatch(getProjects()).then((projects) => {
        const newProject = projects?.data?.find((project) => project?.id === response?.data?.id);
        navigate(`/builder/${newProject?.id}/pages/${newProject?.homepage?.id}`);
        updateProjectCreating(false);
      });
    }).catch(() => updateProjectCreating(false));
  };

  const onSubmit = (templateId) => {
    selectTemplateId(templateId);
    dispatch(getUser()).then((response) => {
      if (response.meta?.remaining_limits?.number_of_projects === 0) {
        toggleModal(true);
      } else {
        submitProject(templateId);
      }
    });
  };

  const getTemplates = () => {
    setLoading(true);
    dispatch(getProjectsTemplates({ page: 1, page_size: 5 })).then((response) => {
      setLoading(false);
      setTemplates(response?.data);
    }).catch(() => setLoading(false));
  };

  useEffect(() => {
    getTemplates();
  }, []);
  return (
    <>
      {loading
        ? (
          <Row>
            {[1, 2, 3, 4, 5, 6]?.map((index) => {
              return (
                <Col md={2} key={index}>
                  <Skeleton key={index} height={220} width="100%" marginBottom={30} />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Row>
            <Col md={2}>
              <div
                className="empty-project-card d-flex flex-column align-items-center justify-content-center"
                onClick={() => onSubmit(undefined)}
              >
                <img src={file} alt="file" height={70} />
                <h6 className="mb-0 text-center text-gray mt-3">قالب فارغ</h6>
              </div>
            </Col>
            {templates?.map((template) => {
              return (
                <Col md={2} key={template.id}>
                  <TemplateCard
                    data={template}
                    onClick={() => onSubmit(template.id)}
                    selectedTemplateId={selectedTemplateId}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      <LimitationModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        onSubmit={() => submitProject(selectedTemplateId)}
      />
    </>
  );
}

export default CreateProjectCarousel;
