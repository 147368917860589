import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required('يجب تعبئة هذه الخانة'),
  email: yup.string().email('يرجى التأكد من الصيغة المدخلة').required('يجب تعبئة هذه الخانة'),
  password: yup.string().required('يجب تعبئة هذه الخانة'),
  phone_number: yup.string().required('يجب تعبئة هذه الخانة'),
}).required();

export default schema;
