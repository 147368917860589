import classNames from 'classnames';
import React from 'react';
import file from '../../../assets/images/file.svg';
import ProgressiveImage from '../../../components/progressive-image';
import placeholder from '../../../assets/images/placeholder.svg';

function TemplateCard({
  data, selectedTemplateId, onClick,
}) {
  return (
    <div className={classNames('template-card', selectedTemplateId === data?.id ? 'blue-border' : '')}>
      <div className="card-content">
        {data?.thumbnail_url
          ? <ProgressiveImage src={data?.thumbnail_url} placeholderSrc={placeholder} alt="thumbnail" className="thumbnail" />
          : <img src={file} alt="file" height={70} />}
        <div className="card-overlay">
          <h5 className="text-white text-center">{data.name}</h5>
          <button
            type="button"
            className="btn btn-blue btn-sm mb-2"
            onClick={(e) => {
              e.stopPropagation();
              window.open(`${data?.public_url}`, '_blank');
            }}
            id="preview_template"
          >
            استعرض القالب
          </button>
          <button
            type="button"
            className="btn btn-navy btn-sm"
            onClick={onClick}
            id="use_template"
          >
            استخدم القالب
          </button>
        </div>
      </div>
    </div>
  );
}

export default TemplateCard;
