import classNames from 'classnames';
import React, { useState, useEffect } from 'react';

function ProgressiveImage({ placeholderSrc, src, ...props }) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ''}
      className={classNames('progressive-img thumbnail', placeholderSrc && imgSrc === placeholderSrc ? 'img-loading' : 'img-loaded')}
    />
  );
}
export default ProgressiveImage;
