import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

function LimitationModal({
  isModalOpen, toggleModal, onSubmit,
}) {
  return (
    <Modal centered isOpen={isModalOpen} toggle={() => toggleModal()}>
      <ModalHeader className="text-gray" toggle={() => toggleModal()}>
        لقد وصلت إلى الحد الأعلى من المشاريع النشطة
      </ModalHeader>
      <ModalBody className="pb-0">
        <p className="text-gray">
          لقد وصلت إلى الحد الأعلى من عدد المشاريع النشطة على الباقة الحالية، في حال الرغبة في المتابعة في إنشاء مشروع جديد سوف يتم أرشفة أحد مشاريعك السابقة بشكل تلقائي٫ هل ترغب في المتابعة؟
        </p>
      </ModalBody>
      <ModalFooter>
        <button type="submit" className="btn btn-blue px-4" onClick={() => onSubmit()}>
          المتابعة
        </button>
        <button type="button" className="btn btn-gray px-4" onClick={() => toggleModal()}>
          إلغاء
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default LimitationModal;
