import React, { useEffect, useState } from 'react';
import {
  Row, Col, Offcanvas, OffcanvasHeader, OffcanvasBody, Spinner,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { createProject, getProjects, getProjectsTemplates } from '../../../../redux/actions/projects.actions';
import file from '../../../../assets/images/blank-project.svg';
import TemplateCard from '../../list/template-card';
import LimitationModal from '../limitation-modal';
import { getUser } from '../../../../redux/actions/user.actions';

function CreateProjectDrawer({
  isOffcanvasOpen, toggleOffcanvas, updateProjectCreating,
}) {
  const [selectedTemplateId, selectTemplateId] = useState(undefined);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [templates, setTemplates] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateDimensions = () => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const submitProject = (templateId) => {
    updateProjectCreating(true);
    const data = { template_id: templateId };
    dispatch(createProject(data)).then((response) => {
      toggleOffcanvas();
      dispatch(getProjects()).then((projects) => {
        const newProject = projects?.data?.find((project) => project?.id === response?.data?.id);
        navigate(`/builder/${newProject?.id}/pages/${newProject?.homepage?.id}`);
        updateProjectCreating(false);
      });
    }).catch(() => {
      updateProjectCreating(false);
    });
  };

  const onSubmit = (templateId) => {
    selectTemplateId(templateId);
    dispatch(getUser()).then((response) => {
      if (response.meta?.remaining_limits?.number_of_projects === 0) {
        toggleModal(true);
      } else {
        submitProject(templateId);
      }
    });
  };

  const getTemplates = () => {
    setLoading(true);
    dispatch(getProjectsTemplates({ page: 1, page_size: 20 })).then((response) => {
      setLoading(false);
      setTemplates(response?.data);
      setPaginationMeta(response?.meta);
    }).catch(() => setLoading(false));
  };

  const loadNextPage = () => {
    dispatch(getProjectsTemplates({ page: paginationMeta?.current_page + 1, page_size: 20 })).then((response) => {
      setTemplates([...templates, ...response?.data]);
      setPaginationMeta(response?.meta);
    });
  };

  return (
    <>
      <Offcanvas
        className="templates-offcanvas"
        direction="start"
        onOpened={() => getTemplates()}
        isOpen={isOffcanvasOpen}
        toggle={() => toggleOffcanvas()}
      >
        <OffcanvasHeader className="text-gray border-bottom" toggle={() => toggleOffcanvas()}>
          قوالب روابط
        </OffcanvasHeader>
        <OffcanvasBody style={{ height: windowHeight - 60 }} id="offcanvasBody">
          {loading
            ? (
              <Row>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]?.map((index) => {
                  return (
                    <Col md={3} key={index}>
                      <Skeleton height={220} width="100%" className="mb-30" />
                    </Col>
                  );
                })}
              </Row>
            )
            : (
              <Row>
                <InfiniteScroll
                  className="row pt-2"
                  dataLength={templates?.length}
                  next={loadNextPage}
                  hasMore={paginationMeta?.current_page !== paginationMeta?.last_page}
                  loader={(
                    <Col md={12} className="d-flex justify-content-center">
                      <Spinner className="text-gray" />
                    </Col>
                  )}
                  scrollableTarget="offcanvasBody"
                >
                  <Col md={3}>
                    <div
                      className="empty-project-card d-flex flex-column align-items-center justify-content-center"
                      onClick={() => onSubmit(undefined)}
                    >
                      <img src={file} alt="file" height={70} />
                      <h6 className="mb-0 text-center text-gray mt-3">قالب فارغ</h6>
                    </div>
                  </Col>
                  {templates?.map((template) => {
                    return (
                      <Col md={3} key={template.id}>
                        <TemplateCard data={template} onClick={() => onSubmit(template.id)} selectedTemplateId={selectedTemplateId} />
                      </Col>
                    );
                  })}
                </InfiniteScroll>
              </Row>
            )}
        </OffcanvasBody>
      </Offcanvas>
      <LimitationModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        onSubmit={() => submitProject(selectedTemplateId)}
      />
    </>
  );
}

export default CreateProjectDrawer;
