import React, { useState, useEffect, useCallback } from 'react';
import {
  Row, Col, Container, Spinner,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import LoadingOverlay from 'react-loading-overlay';
import Header from '../../../components/header';
import CreateProjectDrawer from '../create/drawer';
import { getProjects } from '../../../redux/actions/projects.actions';
import ProjectCard from './project-card';
import emptyIcon from '../../../assets/images/empty-projects.svg';
import EmptyPlaceholder from '../../../components/empty-placeholder';
import CreateProjectCarousel from '../create/carousel';
import VerifyEmail from '../../../components/verify-email';

function ProjectsList() {
  const dispatch = useDispatch();
  const [isOffcanvasOpen, toggleOffcanvas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [creating, setCreating] = useState(false);

  const getProjectsList = () => {
    setLoading(true);
    dispatch(getProjects({ page: 1 })).then((response) => {
      setLoading(false);
      setProjects(response?.data);
      setPaginationMeta(response?.meta);
    }).catch(() => setLoading(false));
  };

  const loadNextPage = () => {
    dispatch(getProjects({ page: paginationMeta?.current_page + 1 })).then((response) => {
      setProjects([...projects, ...response?.data]);
      setPaginationMeta(response?.meta);
    });
  };

  useEffect(() => {
    getProjectsList();
  }, []);

  const updateProjectCreating = useCallback((value) => {
    setCreating(value);
  }, [setCreating]);
  return (
    <LoadingOverlay
      active={creating}
      spinner
      text="جاري إعداد المشروع"
      className="h-100"
    >
      <VerifyEmail />
      <Header />
      <section className="pt-4">
        <Container>
          <Row>
            <Col md={12} className="d-flex align-items-center justify-content-between mb-4">
              <h3 className="text-gray mb-0">صمم موقعك الآن باستخدام قوالب روابط الجاهزة</h3>
              <button type="button" className="btn btn-navy" onClick={() => toggleOffcanvas(!isOffcanvasOpen)} id="view_templates">
                استعرض جميع القوالب
              </button>
            </Col>
          </Row>
          <CreateProjectCarousel updateProjectCreating={updateProjectCreating} />
        </Container>
      </section>
      <section className="pt-4">
        <Container>
          <Row>
            <Col md={12} className="d-flex align-items-center justify-content-between">
              <h3 className="text-gray mb-0">قائمة المشاريع</h3>
            </Col>
          </Row>
        </Container>
      </section>
      {loading
        ? (
          <section className="pt-4">
            <Container>
              <Row>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((index) => {
                  return (
                    <Col md={2} key={index}>
                      <Skeleton height={220} width="100%" className="mb-30" />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>
        )
        : (
          <>
            {projects?.length === 0 ? <EmptyPlaceholder text="لا يوجد مشاريع" icon={emptyIcon} />
              : (
                <section className="pt-4">
                  <Container>
                    <InfiniteScroll
                      className="row"
                      dataLength={projects?.length}
                      next={loadNextPage}
                      hasMore={paginationMeta?.current_page !== paginationMeta?.last_page}
                      loader={(
                        <Col md={12} className="d-flex justify-content-center">
                          <Spinner className="text-gray" />
                        </Col>
                      )}
                    >
                      {projects?.map((project) => {
                        return (
                          <Col md={2} key={project.id}>
                            <ProjectCard data={project} />
                          </Col>
                        );
                      })}
                    </InfiniteScroll>
                  </Container>
                </section>
              )}
          </>
        )}
      <CreateProjectDrawer
        isOffcanvasOpen={isOffcanvasOpen}
        toggleOffcanvas={toggleOffcanvas}
        updateProjectCreating={updateProjectCreating}
      />
    </LoadingOverlay>
  );
}

export default ProjectsList;
