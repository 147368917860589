import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Col, Container, Form, Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from 'react-helmet';
import { login } from '../../../redux/actions/auth.actions';
import { setServerErrors } from '../../../helpers';
import InputField from '../../../components/input';
import HeaderLogo from '../../../components/header-logo';
import schema from './schema';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register, handleSubmit, setError, formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(login(data)).then(() => {
      navigate('/projects');
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  return (
    <>
      <Helmet>
        <title>منصّة روابط | تسجيل الدخول</title>
        <meta name="description" content="قم بتسجيل الدخول الى أداة تصميم المواقع من روابط وابدا رحلة انشاء واطلاق موقعك الإلكتروني." />
      </Helmet>
      <section className="auth-container">
        <HeaderLogo />
        <main>
          <Container>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="white-card d-flex flex-column justify-content-center">
                  <h5 className="text-gray text-center fw-bold mb-3">تسجيل الدخول</h5>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                      label="البريد الإلكتروني"
                      name="email"
                      type="email"
                      errors={errors}
                      {...register('email')}
                    />
                    <InputField
                      label="كلمة المرور"
                      name="password"
                      type="password"
                      dir="ltr"
                      errors={errors}
                      {...register('password')}
                    />
                    <button type="submit" className="btn btn-blue w-100 fw-bold" id="login">تسجيل الدخول</button>
                  </Form>
                  <h6 className="text-gray text-center fw-bold mb-0 mt-4">
                    ليس لديك حساب؟ قم
                    {' '}
                    <Link to="/auth/register" className="text-blue" id="register_login">بإنشاء حساب</Link>
                  </h6>
                  <h6 className="text-gray text-center fw-bold my-2">أو</h6>
                  <Link to="/auth/login-link" className="text-blue text-center fw-bold" id="request_passwordless_login">إرسال رابط تسجيل الدخول إلى البريد الإلكتروني</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </section>
    </>
  );
}

export default Login;
