import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Col, Container, Form, Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { signup } from '../../../redux/actions/auth.actions';
import HeaderLogo from '../../../components/header-logo';
import InputField from '../../../components/input';
import schema from './schema';
import { setServerErrors } from '../../../helpers';
import PhoneField from '../../../components/phone-input';

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register, handleSubmit, setError, formState: { errors }, control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const hubSpotData = {
      submittedAt: new Date(),
      fields: [
        {
          name: 'firstname',
          value: data.name,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'phone',
          value: data.phone_number,
        },
      ],
      context: {
        pageUri: `${window.location.href}`,
        pageName: 'Rawabit - Registration',
      },
    };
    const parsedPhoneNumber = parsePhoneNumber(`${data.phone_number}`);
    const newData = { ...data, phone_number: parsedPhoneNumber?.number, phone_country: parsedPhoneNumber?.country };
    dispatch(signup(newData)).then(() => {
      axios.post(
        'https://api.hsforms.com/submissions/v3/integration/submit/25080975/714cf439-0798-4b9e-9848-035f45e555f8',
        hubSpotData,
      );
      navigate('/projects');
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  return (
    <>
      <Helmet>
        <title>منصّة روابط | إنشاء حساب جديد</title>
        <meta name="description" content="قم بإنشاء حساب جديد لك على منصة روابط وابدأ رحلة إنشاء وإطلاق موقعك الإلكتروني." />
      </Helmet>
      <section className="auth-container">
        <HeaderLogo />
        <main>
          <Container>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="white-card d-flex flex-column justify-content-center">
                  <h5 className="text-gray text-center fw-bold mb-3">إنشاء حساب جديد</h5>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                      label="الاسم الكامل"
                      name="name"
                      type="text"
                      errors={errors}
                      {...register('name')}
                    />
                    <InputField
                      label="البريد الإلكتروني"
                      name="email"
                      type="email"
                      errors={errors}
                      {...register('email')}
                    />
                    <PhoneField
                      label="رقم الهاتف"
                      type="phone_number"
                      errors={errors}
                      control={control}
                      {...register('phone_number')}
                    />
                    <InputField
                      label="كلمة المرور"
                      name="password"
                      type="password"
                      dir="ltr"
                      errors={errors}
                      {...register('password')}
                    />
                    <h6 className="text-gray text-center my-4">
                      عند ضغطك على زر التسجيل، فأنت توافق على
                      {' '}
                      <Link to="/" className="text-blue" id="privacy_policy">سياسة الخصوصية</Link>
                      {' '}
                      و
                      {' '}
                      <a href="https://www.rawabit.me/terms" className="text-blue">الشروط والأحكام</a>
                    </h6>
                    <button type="submit" className="btn btn-blue w-100 fw-bold" id="register">إنشاء حساب</button>
                  </Form>
                  <h6 className="text-gray text-center fw-bold mb-0 mt-4">
                    لديك حساب؟ قم
                    {' '}
                    <Link to="/auth/login" className="text-blue" id="login_register">بتسجيل الدخول</Link>
                  </h6>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      </section>

    </>
  );
}

export default Register;
